// leaderboard.js
import React, { useState, useEffect, useContext } from "react";

/** api part */
import * as constValues from "../../utilities/constants";
import axios from "axios";

import { Helmet } from "react-helmet";

/** Parts */
import LeaderHeader from "../leaderboard/parts/leaderHeader";
import LeaderNav from "../leaderboard/parts/leaderNav";
import LeaderTableItem from "../leaderboard/parts/leaderTableItem";

import AuthContext from "../../store/authcontext";
import Loader from "react-loader-spinner";

const Leaderboard = (props) => {
  const [winnerListGeneral, setWinnerListGeneral] = useState(null);
  const [winnerListRelated, setWinnerListRelated] = useState(null);
  const [leaderPageContent, setLeaderPageContent] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const ctx = useContext(AuthContext);

  useEffect(() => {
    const existingScript = document.getElementById("fscript");
    // const test = document.getElementsByClassName("page-menu-wrap-clone");
    // while (test.length > 0) {
    //   test[0].parentNode.removeChild(test[0]);
    // }

    if (existingScript) {
      existingScript.remove();
      const tag = document.createElement("script");
      tag.src = tag;
      tag.id = "fscript";
      tag.src = "/assets/js/functions.js?v=1.1";
      tag.async = true;
      document.body.appendChild(tag);
    }
  }, [isLoading]);

  useEffect(() => {
    const mobileMenu = document.getElementById("primary-menu-trigger");
    mobileMenu.style.display = "none";
    if (isLoading) {
      mobileMenu.style.display = "none";
    } else {
      mobileMenu.style.display = "flex";
    }
  }, [isLoading]);

  useEffect(() => {
    const getWinnerListGeneral = () => {
      const userToken = localStorage.getItem("REACT_TOKEN_USER");
      const anonymousToken = localStorage.getItem("REACT_TOKEN_ANONYMOUS");
      if (
        (ctx.isLoggedIn && userToken !== null) ||
        (!ctx.isLoggedIn && anonymousToken !== null)
      ) {
        axios
          .post(constValues.API_URL + constValues.API_GET_WINNER_GENERAL, {
            ReqToken: ctx.isLoggedIn ? userToken : anonymousToken,
            ReqData: "",
          })
          .then((response) => {
            setWinnerListGeneral(response.data.ResData);
            setIsLoading(false);
          })
          .catch((error) => console.error("Error", error));
      }
    };
    getWinnerListGeneral();
  }, [ctx.isLoggedIn, ctx.storageDone]);

  useEffect(() => {
    const userToken = localStorage.getItem("REACT_TOKEN_USER");
    const anonymousToken = localStorage.getItem("REACT_TOKEN_ANONYMOUS");
    const getWinnerListRelated = () => {
      if (
        (ctx.isLoggedIn && userToken !== null) ||
        (!ctx.isLoggedIn && anonymousToken !== null)
      ) {
        axios
          .post(constValues.API_URL + constValues.API_GET_WINNER_RELATED, {
            ReqToken: ctx.isLoggedIn ? userToken : anonymousToken,
            ReqData: "",
          })
          .then((response) => {
            setWinnerListRelated(response.data.ResData);
            setIsLoading(false);
          })
          .catch((error) => console.error("Error", error));
      }
    };
    getWinnerListRelated();
  }, [ctx.isLoggedIn]);

  useEffect(() => {
    const getPageContent = () => {
      const userToken = localStorage.getItem("REACT_TOKEN_USER");
      const anonymousToken = localStorage.getItem("REACT_TOKEN_ANONYMOUS");
      setIsLoading(true);
      axios
        .post(constValues.API_URL + constValues.API_GET_SITEMANAGEMENT_VALUES, {
          ReqToken: ctx.isLoggedIn ? userToken : anonymousToken,
          ReqData: constValues.PAGE_GROUP_NAME_LIDERLER,
        })
        .then((response) => {
          setLeaderPageContent(response.data.ResData);
          setIsPageLoading(false);
        })
        .catch((error) => console.error("Error", error));
    };
    getPageContent();
  }, [ctx.isLoggedIn, ctx.storageDone]);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Fikrimiz - Liderler</title>
        <meta name="description" content="Sosyal Yaratıcılık Başlıyor" />
        <meta
          name="keywords"
          content="Fikrimiz, Yaratıcılık, Yarışma, Sosyal, Reklam, Blog"
        />
        <meta property="og:image" content="/assets/img/fikrimiz-org-3.png" />
      </Helmet>
      {isLoading ? (
        <Loader
          style={{ textAlign: "center" }}
          type="ThreeDots"
          color="#31F4E8"
          height={100}
          width={100}
        />
      ) : (
        <section id="content">
          <div className="content-wrap pt-0">
            <div className="section mt-0 overflow-visible">
              <div className="container">
                <LeaderHeader leaderPageContent={leaderPageContent} />
              </div>
            </div>
            <div className="container">
              <div className="row gutter-40 col-mb-80 justify-content-center">
                <div className="postcontent col-lg-8">
                  <div className="single-post mb-0">
                    <div className="entry clearfix">
                      <div className="entry-content">
                        <LeaderTableItem
                          headerId={"section-all"}
                          title={""}
                          winnerListGeneral={winnerListGeneral}
                          winnerListRelated={winnerListRelated}
                          leaderPageContent={leaderPageContent}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </div>
  );
};

export default Leaderboard;
