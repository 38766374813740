//import logo from './logo.svg';
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "react-notifications/lib/notifications.css";
import "react-confirm-alert/src/react-confirm-alert.css";

import React, { useEffect, useContext } from "react";
import Home from "../src/components/home/home";
import Projects from "../src/components/projects/projects";
import Blogs from "../src/components/blogs/bloglist";
import ProjectDetail from "../src/components/projectDetail/projectDetail";
import LeaderBoard from "../src/components/leaderboard/leaderboard.js";
import Contact from "../src/components/contact/contact";
import Profile from "./components/profile/Profile";
import BlogDetail from "./components/blogs/blog";
import ProjectGroup from "./components/projectGroup/projectGroup";

import Layout from "./components/layouts/Layout";

import ScrollToTop from "./components/layouts/ScrollToTop";
import axios from "axios";
import AuthContext from "./store/authcontext";
import { API_GET_TOKEN, API_URL } from "./utilities/constants";

function App() {
  const ctx = useContext(AuthContext);

  useEffect(() => {
    const tokenUrl = `${API_URL}${API_GET_TOKEN}`;
    const getToken = () => {
      const body = {
        ReqToken: "",
        ReqData: {},
      };
      axios
        .post(tokenUrl, body)
        .then(function (response) {
          const res = response.data;
          // sonucu handle et
          if (res.ResCode !== 200) {
            NotificationManager.info(res.ResMessage);
          } else {
            const token = response.data.ResData.token;
            if (
              localStorage.getItem("REACT_TOKEN_USER") === null &&
              localStorage.getItem("REACT_TOKEN_ANONYMOUS") === null
            ) {
              localStorage.setItem("REACT_TOKEN_ANONYMOUS", token);
              ctx.setStorageDone(!ctx.storageDone);
            }
          }
        })
        .catch(function (error) {
          //console.log(error);
        });
    };
    getToken();
  }, []);

  useEffect(() => {
    const tag = document.createElement("script");
    tag.src = tag;
    tag.id = "fscript";
    tag.src = "/assets/js/functions.js?v=1.1";
    tag.async = true;
    document.body.appendChild(tag);
  }, []);

  return (
    <div>
      <Router>
        <Layout>
          <ScrollToTop />
          <Switch>
            <Route path="/projeler/davet/:projectId/:projectTitle">
              <ProjectGroup />
            </Route>
            <Route path="/blog/blog-detay/:blogId">
              <BlogDetail />
            </Route>
            <Route path="/bloglar">
              <Blogs />
            </Route>
            {/** 
            <Route path="/fikrimiz-nedir">
              <OurOpinion />
            </Route>
            */}
            <Route path="/iletisim">
              <Contact />
            </Route>
            <Route path="/liderler">
              <LeaderBoard />
            </Route>
            <Route path="/projeler/proje-detay/:projectId">
              <ProjectDetail />
            </Route>
            <Route path="/projeler">
              <Projects />
            </Route>
            <Route path="/profile">
              <Profile />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </Layout>
      </Router>
      <NotificationContainer />
    </div>
  );
}

export default App;
