// ProjectDetail.js
import React, { useState, Component, useEffect, useContext } from "react";
import {
  BrowserRouter as Router,
  useParams,
  useLocation,
} from "react-router-dom";
import Loader from "react-loader-spinner";

import Header from "../layouts/Header";
import Footer from "../layouts/Footer";

/**Detail Parts */
import DetailHeader from "../projectDetail/parts/detailHeader";
import DetailPostContent from "../projectDetail/parts/detailPostContent";
import DetailSidebar from "../projectDetail/parts/detailSidebar";

/** api part */
import * as constValues from "../../utilities/constants";
import axios from "axios";
import { Helmet } from "react-helmet";

import AuthContext from "../../store/authcontext";

const ProjectDetail = (props) => {
  const ctx = useContext(AuthContext);
  const [project, setProject] = useState([]);
  const [pageContent, setPageContent] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isPageContentLoading, setIsPageContentLoading] = useState(true);
  const [modalIdeaForm, setModalIdeaForm] = useState(false);

  /** get project id */
  let { projectId } = useParams();

  /** */
  useEffect(() => {
    const existingScript = document.getElementById("fscript");
    // const test = document.getElementsByClassName("page-menu-wrap-clone");
    // while (test.length > 0) {
    //   test[0].parentNode.removeChild(test[0]);
    // }

    if (existingScript) {
      existingScript.remove();
      const tag = document.createElement("script");
      tag.src = tag;
      tag.id = "fscript";
      tag.src = "/assets/js/functions.js?v=1.1";
      tag.async = true;
      document.body.appendChild(tag);
    }
  });

  /** Load Projects */
  useEffect(() => {
    const getProjectDetails = () => {
      setIsLoading(true);
      const userToken = localStorage.getItem("REACT_TOKEN_USER");
      const anonymousToken = localStorage.getItem("REACT_TOKEN_ANONYMOUS");
      if (userToken !== null || anonymousToken !== null) {
        axios
          .post(constValues.API_URL + constValues.API_GET_PROJECT_DETAIL, {
            ReqToken: ctx.isLoggedIn ? userToken : anonymousToken,
            ReqData: projectId,
          })
          .then((response) => {
            setProject(response.data.ResData);
            setIsLoading(false);
          })
          .catch((error) => console.error("Error", error));
      }
    };
    getProjectDetails();
  }, [ctx.isLoggedIn, ctx.storageDone]);

  /** Load Page Content */
  useEffect(() => {
    const getAllContents = () => {
      setIsPageContentLoading(true);
      const userToken = localStorage.getItem("REACT_TOKEN_USER");
      const anonymousToken = localStorage.getItem("REACT_TOKEN_ANONYMOUS");
      if (
        (ctx.isLoggedIn && userToken !== null) ||
        (!ctx.isLoggedIn && anonymousToken !== null)
      ) {
        axios
          .post(
            constValues.API_URL + constValues.API_GET_SITEMANAGEMENT_VALUES,
            {
              ReqToken: ctx.isLoggedIn ? userToken : anonymousToken,
              ReqData: constValues.PAGE_GROUP_NAME_PROJEDETAY,
            }
          )
          .then((response) => {
            setPageContent(response.data.ResData);
            setIsPageContentLoading(false);
          })
          .catch((error) => console.error("Error", error));
      }
    };
    getAllContents();
  }, [ctx.isLoggedIn, ctx.storageDone]);

  /** Current Url */
  const currentURL = window.location.href;

  return (
    <div>
      {/** Meta Area */}
      {!isLoading && (
        <Helmet>
          <meta charSet="utf-8" />
          <title>{project ? `Fikrimiz - ${project.name}` : null}</title>
          <meta name="description" content="Sosyal Yaratıcılık Başlıyor." />
          <meta
            name="keywords"
            content="Fikrimiz, Yaratıcılık, Yarışma, Sosyal, Reklam, Blog"
          />
          <meta
            property="og:image"
            content={project && project.medias ? project.medias[0].url : null}
          />
          <meta property="og:url" content={currentURL}></meta>
        </Helmet>
      )}

      {/** Meta Area Ends */}

      <section id="content">
        {isLoading ? (
          <Loader
            style={{ textAlign: "center" }}
            type="ThreeDots"
            color="#31F4E8"
            height={100}
            width={100}
          />
        ) : (
          <div className="content-wrap">
            <div className="container">
              <DetailHeader projectTitle={project ? project.name : null} />
              <div className="row gutter-40 col-mb-80 mt-4">
                {isPageContentLoading ? (
                  <Loader
                    style={{ textAlign: "center" }}
                    type="ThreeDots"
                    color="#31F4E8"
                    height={100}
                    width={100}
                  />
                ) : (
                  <DetailPostContent
                    details={project ? project.details : null}
                    medias={project ? project.medias : null}
                    modalIdeaForm={modalIdeaForm}
                    setModalIdeaForm={setModalIdeaForm}
                    pageContent={pageContent ? pageContent : null}
                    friendsArrayList={project ? project.friends : null}
                    isGroupOwner={project ? project.isGroupOwner : null}
                    isInvolved={project ? project.isInvolved : null}
                    projectTitle={project ? project.name : null}
                    ideas={project ? project.ideas : null}
                  />
                )}

                <DetailSidebar
                  remainingTime={project ? project.remainingTime : null}
                  deadline={project ? project.deadline : null}
                  ideas={project ? project.ideas : null}
                  notificationCount={project ? project.notificationCount : null}
                  id={project ? project.id : null}
                  modalIdeaForm={modalIdeaForm}
                  setModalIdeaForm={setModalIdeaForm}
                  friendsArrayList={project ? project.friends : null}
                  isGroupOwner={project ? project.isGroupOwner : null}
                  isInvolved={project ? project.isInvolved : null}
                  projectTitle={project ? project.name : null}
                />
              </div>
            </div>
          </div>
        )}
      </section>
    </div>
  );
};

export default ProjectDetail;
