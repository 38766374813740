import React, { useState, useEffect, useContext } from "react";
import "../UI/star.css";

import AuthContext from "../../store/authcontext";
import axios from "axios";
import * as constValues from "../../utilities/constants";

const Star = ({ selected = false, onClick = (f) => f }) => (
  <div
    className={selected ? "star selected" : "star unSelected"}
    onClick={onClick}
  />
);

const StarComponent = ({
  totalStars,
  currentStar,
  projectId,
  ideaId,
  setIdeaFormCrud,
  setShowCommentBox,
  ideaFormCrud,
}) => {
  const ctx = useContext(AuthContext);
  const [selectedStar, setSelectedStar] = useState(currentStar);

  // useEffect(() => {
  //   setSelectedStar(currentStar);
  // }, [currentStar]);

  // useEffect(() => {
  //   const sendStarToIdea = () => {
  //     if (selectedStar === null) return;
  //     const userToken = localStorage.getItem("REACT_TOKEN_USER");
  //     const anonymousToken = localStorage.getItem("REACT_TOKEN_ANONYMOUS");
  //     let data = {
  //       projectId: projectId,
  //       ideaId: ideaId,
  //       starCount: selectedStar,
  //     };
  //
  //     if (userToken !== null || anonymousToken !== null) {
  //       axios
  //         .post(constValues.API_URL + constValues.GIVE_STAR_TO_IDEA, {
  //           ReqToken: ctx.isLoggedIn ? userToken : anonymousToken,
  //           ReqData: data,
  //         })
  //         .then((response) => {
  //           setIdeaFormCrud(true);
  //         })
  //         .catch((error) => console.error("Error", error));
  //     }
  //   };
  //   sendStarToIdea();
  // }, [selectedStar]);

  const sendStarToIdea = (star) => {
    if (selectedStar === null) return;
    const userToken = localStorage.getItem("REACT_TOKEN_USER");
    const anonymousToken = localStorage.getItem("REACT_TOKEN_ANONYMOUS");
    let data = {
      projectId: projectId,
      ideaId: ideaId,
      starCount: star,
    };

    if (userToken !== null || anonymousToken !== null) {
      axios
        .post(constValues.API_URL + constValues.GIVE_STAR_TO_IDEA, {
          ReqToken: ctx.isLoggedIn ? userToken : anonymousToken,
          ReqData: data,
        })
        .then((response) => {
          setIdeaFormCrud(!ideaFormCrud);
        })
        .catch((error) => console.error("Error", error));
    }
  };

  return (
    <div className="star-rating">
      {[...Array(totalStars)].map((n, i) => (
        <Star
          key={i}
          selected={i < selectedStar}
          onClick={() => {
            // setSelectedStar(i + 1);
            sendStarToIdea(i + 1);
          }}
        />
      ))}
    </div>
  );
};
export default StarComponent;
