import React, { useCallback } from "react";
import { useState,useEffect,useContext } from "react";
import token from "../../utilities/token";
import Footer from "./Footer";
import Header from "./Header";
import AuthContext from "../../store/authcontext";

const Layout = (props) => {
  const [loginModal, setLoginModal] = useState(false);
  const ctx = useContext(AuthContext);

  return (
    <React.Fragment>
      <Header loginModal={loginModal} setLoginModal={setLoginModal} />
      <main>{props.children}</main>
      <Footer />
    </React.Fragment>
  );
};

export default Layout;
