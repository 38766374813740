// Phone Invites.js
import React, { useState, useContext } from "react";

import { NotificationManager } from "react-notifications";
import { useParams } from "react-router-dom";
import Loader from "react-loader-spinner";

import useForm from "../../UI/useForm";
import validate from "../../UI/validations/phoneInviteFormValidation";

/** api part */
import * as constValues from "../../../utilities/constants";
import axios from "axios";

import AuthContext from "../../../store/authcontext";

const PhoneInvites = (props) => {
  const ctx = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  let { projectId } = useParams();

  const { values, handleChange, handleSubmit, errors } = useForm(
    sendForm,
    validate
  );

  function sendForm() {
    postForm(values.phoneNumber);
  }

  const postForm = (phoneNumber) => {
    setIsLoading(true);
    //alert(projectId);
    const userToken = localStorage.getItem("REACT_TOKEN_USER");
    const anonymousToken = localStorage.getItem("REACT_TOKEN_ANONYMOUS");
    if (userToken !== null || anonymousToken !== null) {
      axios
        .post(constValues.API_URL + constValues.GET_PROJECT_SEND_INVITATION, {
          ReqToken: ctx.isLoggedIn ? userToken : anonymousToken,
          ReqData: {
            userInvitationId: phoneNumber,
            userFromType: constValues.INVITATION_TYPE_PHONE,
            projectId: projectId,
          },
        })
        .then((response) => {
          NotificationManager.info(response.data.ResMessage);
          props.setInvitationStatus(true);
          setIsLoading(false);
        })
        .catch((error) => console.error("Error", error));
    }
  };

  return (
    <div
      id="phoneInvitesModal"
      className="modal fade show"
      tabIndex={-1}
      aria-labelledby="phoneInvitesModal"
      style={{ display: "none", paddingRight: "17px" }}
      aria-modal="true"
      role="dialog"
    >
      <div className="modal-dialog">
        <div className="modal-body">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="myModalLabel">
                Telefon Numarası ile Davet Edin
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-hidden="true"
              >
                ×
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Telefon Numarası</label>
                    <input
                      type="number"
                      id="phoneNumber"
                      name="phoneNumber"
                      className="form-control not-dark"
                      placeholder="Telefon Numarası ( 5XXXXXXXXX )"
                      onChange={handleChange}
                      value={values.phoneNumber || ""}
                      required
                    />
                    {errors.phoneNumber && (
                      <span className="text-danger">{errors.phoneNumber}</span>
                    )}
                  </div>
                  {isLoading ? (
                    <Loader
                      style={{ textAlign: "center" }}
                      type="ThreeDots"
                      color="#31F4E8"
                      height={50}
                      width={50}
                    />
                  ) : (
                    <input
                      type="submit"
                      className="btn btn-primary mt-3"
                      value="Davet Gönder"
                      onClick={handleSubmit}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PhoneInvites;
