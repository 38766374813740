// detailGroupFriends.js
import React, { useState, Component, useEffect, useContext } from "react";

import AuthContext from "../../../store/authcontext";

const DetailGroupFriends = (props) => {
  return (
    <div className="card mt-4">
      <div className="card-header">
        <strong>Proje Grubunda Yer Alanlar </strong>
      </div>
      <div className="card-body">
        <table className="table">
          <thead>
            <tr>
              <th width="50">Görsel</th>
              <th>Kişi</th>
            </tr>
          </thead>
          <tbody>
            {props.friendsArrayList && props.friendsArrayList.length > 0 ? (
              props.friendsArrayList.map((friend, i) => (
                <tr key={i}>
                  <td>
                    <div className="testi-image">
                      <a href="#">
                        <img
                          src={
                            friend.profileImage
                              ? friend.profileImage
                              : "/assets/img/dummy-user.jpg"
                          }
                          alt={friend.fullName}
                        />
                      </a>
                    </div>
                  </td>
                  <td>{friend.fullName}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="2">Kişi bulunamadı...</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DetailGroupFriends;
