// sendIdea.js
import React, { useEffect, useState, useContext } from "react";
import Loader from "react-loader-spinner";
import { NotificationManager } from "react-notifications";

/** api  */
import * as constValues from "../../utilities/constants";
import useForm from "../UI/useForm";
import validate from "../UI/validations/ideaFormValidation";
import axios from "axios";
import AuthContext from "../../store/authcontext";
import { confirmAlert } from "react-confirm-alert";

const IdeaEditForm = (props) => {
  let projectId = props.projectId; // project id
  let ideaId = props.ideaObj.id; // idea id
  const [caption, setCaption] = useState(props.ideaObj.caption);
  const [idea, setIdea] = useState(props.ideaObj.idea);

  const { values, handleChange, handleSubmit, errors } = useForm(
    sendForm,
    validate
  );

  function sendForm() {
    updateForm(values.caption, values.idea);
  }

  const [isLoading, setIsLoading] = useState(false);
  const ctx = useContext(AuthContext);

  useEffect(() => {
    values.caption = caption;
    values.idea = idea;
  }, [idea, caption]);

  const updateForm = (caption, idea) => {
    setIsLoading(true);
    axios
      .post(constValues.API_URL + constValues.API_GET_UPDATE_IDEA, {
        ReqToken: ctx.isLoggedIn
          ? localStorage.getItem("REACT_TOKEN_USER")
          : localStorage.getItem("REACT_TOKEN_ANONYMOUS"),
        ReqData: {
          id: ideaId,
          projectId: projectId,
          caption: caption,
          idea: idea,
        },
      })
      .then((response) => {
        NotificationManager.info(response.data.ResMessage);

        setIsLoading(false);
        props.setIdeaFormCrud(!props.ideaFormCrud);
        removeCommentBox();
      })
      .catch((error) => {
        console.error("Error", error);
        setIsLoading(false);
      });
  };

  const removeCommentBox = () => {
    props.setShowCommentBox(false);
    const element = document.getElementsByClassName("modal-backdrop");
    element[0].remove();
  };

  const deleteIdeaRecord = (ideaId) => {
    axios
      .post(constValues.API_URL + constValues.API_GET_DELETE_IDEA, {
        ReqToken: ctx.isLoggedIn
          ? localStorage.getItem("REACT_TOKEN_USER")
          : localStorage.getItem("REACT_TOKEN_ANONYMOUS"),
        ReqData: ideaId,
      })
      .then((response) => {
        NotificationManager.info(response.data.ResMessage);
        props.setIdeaFormCrud(!props.ideaFormCrud);
        removeCommentBox();
      })
      .catch((error) => console.error("Error", error));
  };

  const deleteIdea = (id) => {
    confirmAlert({
      title: "Silme işlemini onaylayın",
      message: "Bu fikri silmek istediğinize emin misiniz?",
      buttons: [
        {
          label: "Evet",
          onClick: () => deleteIdeaRecord(id),
        },
        {
          label: "Hayır",
        },
      ],
    });
  };

  return (
    <React.Fragment>
      {caption && idea && (
        <div className="mb-0 row">
          <div className="col-12">
            <input
              type="text"
              id="caption"
              name="caption"
              className="form-control not-dark"
              placeholder="Başlık (Max Karakter : 40)"
              onChange={(event) => {
                setCaption(event.target.value);
                handleChange(event);
              }}
              value={caption}
              maxLength="40"
              required
            />
            {caption && <span>{caption.length}/40</span>}
            {errors.caption && (
              <span className="text-danger">{errors.caption}</span>
            )}
          </div>
          <div className="col-12 mt-2">
            <textarea
              id="idea"
              name="idea"
              rows={4}
              cols={50}
              className="form-control not-dark"
              placeholder="Fikrinizi belirtin (Max Karakter : 600)"
              onChange={(event) => {
                setIdea(event.target.value);
                handleChange(event);
              }}
              value={idea}
              maxLength="600"
              required
            />
            {idea && <span>{idea.length}/600</span>}
            {errors.idea && (
              <span className="text-danger ml-3">{errors.idea}</span>
            )}
          </div>

          <div className="col-12 mt-4">
            {isLoading ? (
              <Loader
                style={{ textAlign: "center" }}
                type="ThreeDots"
                color="#31F4E8"
                height={50}
                width={50}
              />
            ) : (
              <div className="d-flex justify-content-between">
                <input
                  type="submit"
                  className="button btn-block m-0"
                  value="Sil"
                  onClick={() => deleteIdea(ideaId)}
                  style={{ width: "40%" }}
                />
                <input
                  type="submit"
                  className="button btn-block m-0"
                  value="Gönder"
                  onClick={handleSubmit}
                  style={{ width: "40%" }}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default IdeaEditForm;
