// GroupTable.js
import React, { useEffect, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import Loader from "react-loader-spinner";
import axios from "axios";
import * as constValues from "../../../utilities/constants";

const GroupTable = ({
  projectId,
  projectTitle,
  invitationStatus,
  setInvitationStatus,
}) => {
  const [projectGroupList, setProjectGroupList] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const userToken = localStorage.getItem("REACT_TOKEN_USER");
  const [cancelModal, setCancelModal] = useState(false);
  const [selectedInvitation, setSelectedInvitation] = useState(null);

  useEffect(() => {
    const getInvitedProjectGroupList = () => {
      setIsLoading(true);
      axios
        .post(constValues.API_URL + constValues.GET_PROJECT_GROUP_INVITES, {
          ReqToken: userToken,
          ReqData: projectId,
        })
        .then((response) => {
          setProjectGroupList(response.data.ResData);
          setIsLoading(false);
        })
        .catch((error) => console.error("Error", error));
    };
    getInvitedProjectGroupList();
  }, [invitationStatus]);

  const cancelInvitation = () => {
    setIsLoading(true);
    axios
      .post(constValues.API_URL + constValues.GET_PROJECT_CANCEL_INVITATION, {
        ReqToken: userToken,
        ReqData: {
          projectId: projectId,
          invitationId: selectedInvitation.invitationId,
          userId: selectedInvitation.userId,
        },
      })
      .then((response) => {
        // setProjectGroupList(response.data.ResData);
        console.log("iptalsonuc", response);
        setCancelModal(false);
        setInvitationStatus(true);
        setIsLoading(false);
      })
      .catch((error) => console.error("Error", error));
  };

  const cancelInvitationModal = (invitation) => {
    return (
      <React.Fragment>
        <div
          className="mfp-bg mfp-no-margins mfp-fade mfp-ready"
          style={{ opacity: 0.2 }}
          onClick={() => setCancelModal(false)}
        ></div>
        <div
          className="myModal"
          style={{ overflowY: "auto", maxHeight: "90%" }}
        >
          <div className="modal-body">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title mx-auto">Daveti İptal Et</h4>
              </div>
              {isLoading ? (
                <Loader
                  style={{ textAlign: "center" }}
                  type="ThreeDots"
                  color="#31F4E8"
                  height={50}
                  width={50}
                />
              ) : (
                <div className="modal-body">
                  <a
                    className="button button-3d button-rounded button-red"
                    onClick={() => setCancelModal(false)}
                  >
                    <i className="icon-line2-close"></i>Hayır
                  </a>
                  <a
                    className="button button-3d button-rounded button-green"
                    onClick={() => cancelInvitation()}
                  >
                    <i className="icon-line-check"></i>Evet
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  const SetInvitationStatus = (codeNumber) => {
    switch (codeNumber) {
      case 2: // Accepted
        return "<code style='color:green'>Kabul etti</code >";
        break;
      case 3: // Waiting
        return "<code style='color:orange'>Bekleniyor</code >";
        break;
      case 4: // Not Accepted
        return "<code style='color:red'>Kabul Etmedi</code >";
        break;
      case 5: // Canceled
        return "<code style='color:red'>İptal Edildi</code >";
        break;
      default:
        break;
    }
  };

  return (
    <div className="col-md-9">
      {cancelModal && cancelInvitationModal()}
      <div className="heading-block border-0">
        <h3>
          {decodeURIComponent(projectTitle)} için Fikir Grubu Davet Listesi
        </h3>
        <span>
          Aşağıdaki liste bu proje için davet gönderdiğiniz kişileri
          barındırmaktadır. Sağ tarafta bulunan seçenekleri kullanarak projeye
          yeni insanlar davet edebilirsiniz!
        </span>
      </div>
      <div className="clear" />
      <div className="row clearfix">
        <div className="col-lg-12">
          <div className="tabs tabs-alt clearfix" id="tabs-profile">
            <div className="tab-container">
              <div className="tab-content clearfix" id="tab-feeds">
                <table className="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>Kişi</th>
                      <th>Durum</th>
                      <th>İşlemler</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading ? (
                      <tr>
                        <td colSpan="3">
                          <Loader
                            style={{ textAlign: "center" }}
                            type="ThreeDots"
                            color="#31F4E8"
                            height={50}
                            width={50}
                          />
                        </td>
                      </tr>
                    ) : projectGroupList ? (
                      projectGroupList.map((inviteObj, i) => {
                        return (
                          <tr key={i}>
                            <td>{inviteObj.userName}</td>
                            <td>
                              {ReactHtmlParser(
                                SetInvitationStatus(inviteObj.invitationStatus)
                              )}
                            </td>
                            <td className="text-center">
                              {inviteObj.invitationStatus == 3 && (
                                <i
                                  className="icon-line2-close cursorPointer"
                                  title="Daveti iptal et"
                                  onClick={() => {
                                    setCancelModal(true);
                                    setSelectedInvitation(inviteObj);
                                  }}
                                />
                              )}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="3">Kayıt bulunamadı</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GroupTable;
