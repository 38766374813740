// Header.js
import React, { useEffect, useState, useContext } from "react";

/**route */
import { Link, useLocation } from "react-router-dom";
import AuthContext from "../../store/authcontext";
import { API_URL, TOTAL_NOTIFICATION_COUNT } from "../../utilities/constants";
import axios from "axios";

import LoginForm from "../forms/login";
import ReactGA from "react-ga";


const Header = ({ loginModal, setLoginModal }) => {
  const authCtx = useContext(AuthContext);
  const [userInfo, setUserInfo] = useState(authCtx.userInfo);
  const [notificationCount, setNotificationCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    ReactGA.initialize("UA-216365397-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
  });


  useEffect(() => {
    setUserInfo(authCtx.userInfo);
  }, [authCtx.userInfo]);

  useEffect(() => {
    const getTotalNotificationCount = () => {
      const userToken = localStorage.getItem("REACT_TOKEN_USER");
      const anonymousToken = localStorage.getItem("REACT_TOKEN_ANONYMOUS");
      axios
        .post(API_URL + TOTAL_NOTIFICATION_COUNT, {
          ReqToken: authCtx.isLoggedIn ? userToken : anonymousToken,
          ReqData: "",
        })
        .then((response) => {
          setNotificationCount(response.data.ResData);
          setIsLoading(true);
        })
        .catch((error) => console.error("Error", error));
    };
    getTotalNotificationCount();
  }, [authCtx.isLoggedIn, authCtx.isNotificationRead]);

  /**url paths */
  const location = useLocation();

  let urlPath = location.pathname;
  let projectPath = location.pathname.split("/")[1];

  return (
    <div>
      {loginModal && <LoginForm loginModal={setLoginModal} />}

      <header
        id="header"
        className="transparent-header header-size-custom"
        data-sticky-shrink="false"
      >
        <div id="header-wrap">
          <div className="container">
            <div className="header-row">
              {/* Logo
						============================================= */}
              <div id="logo">
                <a href="/" className="standard-logo">
                  <img
                    src="/assets/img/fikrimiz-org-3.png"
                    alt="Fikrimiz Logo"
                  />
                </a>
                <a href="/" className="retina-logo">
                  <img
                    src="/assets/img/fikrimiz-org-3.png"
                    alt="Fikrimiz Logo"
                  />
                </a>
              </div>
              {/* #logo end */}
              {/**  SEARCH AND LOGIN BUTTON
              <div className="header-misc">
                <div id="top-search" className="header-misc-icon">
                  <a href="#" id="top-search-trigger">
                    <i className="icon-line-search" />
                    <i className="icon-line-cross" />
                  </a>
                </div>
                <button
                  className="button button-small font-weight-semibold button-border button-rounded ls0 font-weight-medium nott"
                  onClick={() => setLoginModal(true)}
                >
                  Start A Campaign
                </button>
              </div>
              */}
              <div id="primary-menu-trigger">
                <svg className="svg-trigger" viewBox="0 0 100 100">
                  <path d="m 30,33 h 40 c 3.722839,0 7.5,3.126468 7.5,8.578427 0,5.451959 -2.727029,8.421573 -7.5,8.421573 h -20" />
                  <path d="m 30,50 h 40" />
                  <path d="m 70,67 h -40 c 0,0 -7.5,-0.802118 -7.5,-8.365747 0,-7.563629 7.5,-8.634253 7.5,-8.634253 h 20" />
                </svg>
              </div>
              {/* Primary Navigation
						============================================= */}
              <nav className="primary-menu">
                <ul className="menu-container">
                  {/**
                  {urlPath == "/fikrimiz-nedir" ? (
                    <li className="current menu-item">
                      <a className="menu-link" href="/fikrimiz-nedir">
                        <div>Fikrimiz Nedir?</div>
                      </a>
                    </li>
                  ) : (
                    <li className="menu-item">
                      <a className="menu-link" href="/fikrimiz-nedir">
                        <div>Fikrimiz Nedir?</div>
                      </a>
                    </li>
                  )}
                  */}

                  {urlPath == "/projeler" || projectPath == "projeler" ? (
                    <li className="current menu-item">
                      <Link className="menu-link" to="/projeler">
                        <div>Projeler</div>
                      </Link>
                    </li>
                  ) : (
                    <li className="menu-item">
                      <Link className="menu-link" to="/projeler">
                        <div>Projeler</div>
                      </Link>
                    </li>
                  )}

                  {urlPath == "/bloglar" || projectPath == "blog" ? (
                    <li className="current menu-item">
                      <Link className="menu-link" to="/bloglar">
                        <div>Bloglar</div>
                      </Link>
                    </li>
                  ) : (
                    <li className="menu-item">
                      <Link className="menu-link" to="/bloglar">
                        <div>Bloglar</div>
                      </Link>
                    </li>
                  )}

                  {urlPath == "/liderler" ? (
                    <li className="current menu-item">
                      <Link className="menu-link" to="/liderler">
                        <div>Liderler</div>
                      </Link>
                    </li>
                  ) : (
                    <li className="menu-item">
                      <Link className="menu-link" to="/liderler">
                        <div>Liderler</div>
                      </Link>
                    </li>
                  )}

                  {urlPath == "/iletisim" ? (
                    <li className="current menu-item">
                      <Link className="menu-link" to="/iletisim">
                        <div>İletişim</div>
                      </Link>
                    </li>
                  ) : (
                    <li className="menu-item">
                      <Link className="menu-link" to="/iletisim">
                        <div>İletişim</div>
                      </Link>
                    </li>
                  )}

                  <li className="menu-item">
                    {userInfo ? (
                      <Link to="/profile" className="menu-link">
                        <div>
                          <i className="icon-line2-user font-weight-semibold" />
                          {userInfo.name} {userInfo.lastName}
                        </div>
                      </Link>
                    ) : (
                      <button
                        className="buttonLink menu-link"
                        onClick={() => setLoginModal(true)}
                      >
                        <div>
                          <i className="icon-line2-user font-weight-semibold" />
                          Kullanıcı Girişi
                        </div>
                      </button>
                    )}
                  </li>
                  <li className="menu-item">
                    {userInfo ? (
                      <Link to="/projeler">
                        <span className="header-notification-number">
                          {isLoading ? notificationCount : null}
                        </span>
                      </Link>
                    ) : null}
                  </li>
                </ul>
              </nav>
              {/* #primary-menu end */}
              {/*<form*/}
              {/*  className="top-search-form"*/}
              {/*  action="search.html"*/}
              {/*  method="get"*/}
              {/*>*/}
              {/*  <input*/}
              {/*    type="text"*/}
              {/*    name="q"*/}
              {/*    className="form-control"*/}
              {/*    defaultValue=""*/}
              {/*    placeholder="Type & Hit Enter.."*/}
              {/*    autoComplete="off"*/}
              {/*  />*/}
              {/*</form>*/}
            </div>
          </div>
        </div>
        <div className="header-wrap-clone" />
      </header>
      {/* #header end */}
    </div>
  );
};

export default Header;
