// blogs.js
import React, { useState, Component, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";

import BlogHeader from "../blogs/parts/blogHeader";
import BlogContent from "../blogs/parts/blogContent";

/** api part */
import * as constValues from "../../utilities/constants";
import axios from "axios";

import AuthContext from "../../store/authcontext";
import Loader from "react-loader-spinner";

const BlogList = () => {
  const [blogList, setBlogList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const ctx = useContext(AuthContext);

  useEffect(() => {
    const getProjects = () => {
      setIsLoading(true);
      const userToken = localStorage.getItem("REACT_TOKEN_USER");
      const anonymousToken = localStorage.getItem("REACT_TOKEN_ANONYMOUS");
      if (userToken !== null || anonymousToken !== null) {
        axios
          .post(constValues.API_URL + constValues.GET_BLOGS, {
            ReqToken: ctx.isLoggedIn ? userToken : anonymousToken,
            ReqData: {},
          })
          .then((response) => {
            setBlogList(response.data.ResData);
            setIsLoading(false);
          })
          .catch((error) => console.error("Error", error));
      }
    };
    getProjects();
  }, [ctx.isLoggedIn, ctx.storageDone]);

  useEffect(() => {
    const existingScript = document.getElementById("fscript");
    if (existingScript) {
      existingScript.remove();
      const tag = document.createElement("script");
      tag.src = tag;
      tag.id = "fscript";
      tag.src = "/assets/js/functions.js?v=1.1";
      tag.async = true;
      document.body.appendChild(tag);
    }
  }, []);

  useEffect(() => {
    const mobileMenu = document.getElementById("primary-menu-trigger");
    mobileMenu.style.display = "none";
    if (isLoading) {
      mobileMenu.style.display = "none";
    } else {
      mobileMenu.style.display = "flex";
    }
  }, [isLoading]);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Fikrimiz - Bloglar</title>
        <meta name="description" content="Sosyal Yaratıcılık Başlıyor" />
        <meta
          name="keywords"
          content="Fikrimiz, Yaratıcılık, Yarışma, Sosyal, Reklam, Blog"
        />
        <meta property="og:image" content="/assets/img/fikrimiz-org-3.png" />
      </Helmet>
      {
        <section id="content">
          <div className="content-wrap pt-0">
            <BlogHeader />
            {isLoading ? (
              <Loader
                style={{ textAlign: "center" }}
                type="ThreeDots"
                color="#31F4E8"
                height={100}
                width={100}
              />
            ) : (
              blogList && <BlogContent blogList={blogList} />
            )}
          </div>
        </section>
      }
    </div>
  );
};
export default BlogList;
