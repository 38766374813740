// notificationModal.js
import React, { useState, useContext } from "react";
/** api part */
import * as constValues from "../../../utilities/constants";
import axios from "axios";
import AuthContext from "../../../store/authcontext";
import { NotificationManager } from "react-notifications";

const NotificationModal = (props) => {
  const ctx = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);

  /** invitation id */
  let invitationId = props.invitationId;

  const AcceptInvitation = () => {
    const userToken = localStorage.getItem("REACT_TOKEN_USER");
    const anonymousToken = localStorage.getItem("REACT_TOKEN_ANONYMOUS");
    if (userToken !== null || anonymousToken !== null) {
      axios
        .post(constValues.API_URL + constValues.GET_PROJECT_ACCEPT_INVITATION, {
          ReqToken: ctx.isLoggedIn ? userToken : anonymousToken,
          ReqData: invitationId,
        })
        .then((response) => {
          NotificationManager.info(response.data.ResMessage);
          //alert(response.data.ResMessage);
          setIsLoading(true);
          setTimeout(() => {
            window.parent.location.reload();
          }, 3000);
        })
        .catch((error) => console.error("Error", error));
    }
  };

  const RejectInvitation = () => {
    const userToken = localStorage.getItem("REACT_TOKEN_USER");
    const anonymousToken = localStorage.getItem(
      "GET_PROJECT_REJECT_INVITATION"
    );
    if (userToken !== null || anonymousToken !== null) {
      axios
        .post(constValues.API_URL + constValues.GET_PROJECT_REJECT_INVITATION, {
          ReqToken: ctx.isLoggedIn ? userToken : anonymousToken,
          ReqData: invitationId,
        })
        .then((response) => {
          //alert(response.data.ResMessage);
          NotificationManager.info(response.data.ResMessage);
          setIsLoading(true);
          setTimeout(() => {
            window.parent.location.reload();
          }, 3000);
        })
        .catch((error) => console.error("Error", error));
    }
  };

  return (
    <React.Fragment>
      <div
        className="mfp-bg mfp-no-margins mfp-fade mfp-ready"
        style={{
          opacity: 0.2,
        }}
        onClick={() => {
          props.setNotificationModal(false);
        }}
      ></div>
      <div className="myModal" style={{ overflowY: "auto", maxHeight: "90%" }}>
        <div className="modal-body">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="myModalLabel">
                Davet Durumu
              </h4>
            </div>
            <div className="modal-body">
              <a
                onClick={() => {
                  RejectInvitation();
                }}
                className="button button-3d button-rounded button-red"
              >
                <i className="icon-line2-close"></i>Red
              </a>
              <a
                onClick={() => {
                  AcceptInvitation();
                }}
                className="button button-3d button-rounded button-green"
              >
                <i className="icon-line-check"></i>Kabul
              </a>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default NotificationModal;
