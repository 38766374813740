import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import {
  API_URL,
  GET_USER_URL,
  UPDATE_PROFILE_URL,
} from "../../../utilities/constants";
import Input from "../../UI/Input";
import AuthContext from "../../../store/authcontext";

const ProfileModal = ({ profileModal }) => {
  const [isProfilFormValid, setIsProfilFormValid] = useState(false);
  const [validationError, setValidationError] = useState("");
  const [birdthDate, setBirdthDate] = useState(null);
  const [profileImage, setProfileImage] = useState(null);
  const [profileForm, setProfileForm] = useState({
    name: {
      elementType: "input",
      elementConfig: {
        type: "text",
        placeholder: "Ad",
      },
      value: "",
      validation: {
        required: true,
        minLength: 2,
        maxLength: 15,
      },
      valid: true,
      touched: true,
      divClassName: "col-md-6 mt-4",
    },
    lastName: {
      elementType: "input",
      elementConfig: {
        type: "text",
        placeholder: "Soyad",
      },
      value: "",
      validation: {
        required: true,
        minLength: 2,
        maxLength: 15,
      },
      valid: true,
      touched: true,
      divClassName: "col-md-6 mt-4",
    },
    phoneNumber: {
      elementType: "input",
      elementConfig: {
        type: "number",
        placeholder: "Telefon (5xxxxxxxxx)",
      },
      value: "",
      validation: {
        required: true,
        isNumeric: true,
        minLength: 10,
        maxLength: 10,
      },
      valid: true,
      touched: true,
      divClassName: "col-md-6 mt-4",
      disabled: true,
    },
    email: {
      elementType: "input",
      elementConfig: {
        type: "email",
        placeholder: "Email",
      },
      value: "",
      validation: {
        required: false,
        minLength: 5,
        maxLength: 30,
        isEmail: true,
      },
      valid: true,
      touched: true,
      divClassName: "col-md-6 mt-4",
    },
    gender: {
      elementType: "select",
      elementConfig: {
        type: "select",
        placeholder: "Cinsiyet",
        options: [
          { value: "1", displayValue: "Erkek" },
          { value: "2", displayValue: "Kadın" },
        ],
      },
      value: 1,
      validation: {
        required: false,
      },
      valid: true,
      touched: true,
      divClassName: "col-md-6 mt-4",
    },
    education: {
      elementType: "select",
      elementConfig: {
        type: "select",
        placeholder: "Eğitim Durumu",
        options: [
          { value: "Yüksek Öğrenim", displayValue: "Yüksek Öğrenim" },
          { value: "Lise ve Dengi", displayValue: "Lise ve Dengi" },
          { value: "İlköğretim", displayValue: "İlköğretim" },
          { value: "Okur Yazar", displayValue: "Okur Yazar" },
        ],
      },
      value: "",
      validation: {
        required: false,
      },
      valid: true,
      touched: true,
      divClassName: "col-md-6 mt-4",
    },
    maritalStatus: {
      elementType: "select",
      elementConfig: {
        type: "select",
        placeholder: "Medeni Hal",
        options: [
          { value: "Bekar", displayValue: "Bekar" },
          { value: "Evli", displayValue: "Evli" },
        ],
      },
      value: "",
      validation: {
        required: false,
      },
      valid: true,
      touched: true,
      divClassName: "col-md-6 mt-4",
    },
    work: {
      elementType: "select",
      elementConfig: {
        type: "select",
        placeholder: "Meslek",
        options: [
          { value: "Öğrenci", displayValue: "Öğrenci" },
          { value: "Kurumda Çalışan", displayValue: "Kurumda Çalışan" },
          { value: "Serbest Meslek", displayValue: "Serbest Meslek" },
          { value: "Ev Hanımı", displayValue: "Ev Hanımı" },
          { value: "Emekli", displayValue: "Emekli" },
          { value: "Diğer", displayValue: "Diğer" },
        ],
      },
      value: "",
      validation: {
        required: false,
      },
      valid: true,
      touched: true,
      divClassName: "col-md-6 mt-4",
    },
    birthDate: {
      elementType: "date",
      elementConfig: {
        type: "text",
        placeholder: "Doğum Tarihi",
      },
      validation: {
        required: true,
      },
      value: birdthDate,
      valid: true,
      touched: true,
      divClassName: "col-md-6 mt-4",
      placeholderText: "Doğum Tarihi",
    },
    password: {
      elementType: "input",
      elementConfig: {
        type: "password",
        placeholder: "Şifre",
      },
      value: "",
      validation: {
        required: false,
        minLength: 4,
        maxLength: 30,
      },
      valid: true,
      touched: true,
      divClassName: "col-md-6 mt-4",
    },

    imageData: {
      elementType: "image",
      elementConfig: {
        type: "file",
        placeholder: "Profil Resmi",
        accept: "image/*",
      },
      value: profileImage,
      validation: {
        required: false,
      },
      valid: true,
      touched: true,
      divClassName: "col-md-12 mt-4",
      label: "Profil Resmi",
    },
  });
  const updateProfilUrl = `${API_URL}${UPDATE_PROFILE_URL}`;
  const getUserUrl = `${API_URL}${GET_USER_URL}`;
  const authCtx = useContext(AuthContext);

  // !authCtx.isLoggedIn && history.push("/");

  useEffect(() => {
    const getUser = () => {
      const body = {
        ReqToken: localStorage.getItem("REACT_TOKEN_USER"),
        ReqData: {},
      };

      axios
        .post(getUserUrl, body)
        .then(function (response) {
          const res = response.data;
          // sonucu handle et
          if (res.ResCode !== 200) {
            //alert(res.ResMessage);
            NotificationManager.info(res.ResMessage);
          } else {
            // kayit basarili burada gerekli islemleri yapacagiz
            const data = res.ResData;

            setProfileForm((prevState) => ({
              ...prevState,
              name: {
                ...prevState.name,
                value: data.name ? data.name : "",
              },
              lastName: {
                ...prevState.lastName,
                value: data.lastName ? data.lastName : "",
              },
              phoneNumber: {
                ...prevState.phoneNumber,
                value: data.phoneNumber ? data.phoneNumber : "",
              },
              email: {
                ...prevState.email,
                value: data.email && data.email,
              },
              gender: {
                ...prevState.gender,
                value: data.gender ? data.gender : 1,
              },
              birthDate: {
                ...prevState.birthDate,
                value: data.birthDate ? data.birthDate : "",
              },
              education: {
                ...prevState.education,
                value: data.education ? data.education : "",
              },
              maritalStatus: {
                ...prevState.maritalStatus,
                value: data.maritalStatus ? data.maritalStatus : "",
              },
              work: {
                ...prevState.work,
                value: data.work ? data.work : "",
              },
            }));

            setBirdthDate(new Date(data.birthDate));
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    getUser();
  }, []);

  useEffect(() => {
    setProfileForm((prevState) => ({
      ...prevState,
      imageData: {
        ...prevState.imageData,
        value: profileImage,
      },
    }));
  }, [profileImage]);

  useEffect(() => {
    const existingScript = document.getElementById("fscript");
    if (existingScript) {
      existingScript.remove();
      const tag = document.createElement("script");
      tag.src = tag;
      tag.id = "fscript";
      tag.src = "/assets/js/functions.js?v=1.1";
      tag.async = true;
      document.body.appendChild(tag);
    }
  });

  const profileFormArray = [];
  for (const key in profileForm) {
    profileFormArray.push({
      id: key,
      config: profileForm[key],
    });
  }

  const inputChangedHandler = (event, inputIdentifier) => {
    let updatedForm = { ...profileForm };

    const updatedFormElement = { ...updatedForm[inputIdentifier] };
    updatedFormElement.value = event.target.value;
    updatedFormElement.valid = checkValidity(
      updatedFormElement.value,
      updatedFormElement.validation,
      updatedFormElement.elementConfig.placeholder
    );

    updatedFormElement.touched = true;
    updatedForm[inputIdentifier] = updatedFormElement;

    let formValid = true;

    for (const inputIdentifier in updatedForm) {
      formValid = updatedForm[inputIdentifier].valid && formValid;
    }

    setProfileForm(updatedForm);
    setIsProfilFormValid(formValid);
  };

  const checkValidity = (value, rules, placeholder) => {
    let isValid = true;

    if (rules.required) {
      if (value.trim() === "") {
        setValidationError(`${placeholder} alanı boş olamaz.`);
        isValid = false;
      }
    }

    if (rules.isEmail) {
      const pattern =
        /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
      if (!pattern.test(value) && isValid) {
        setValidationError(
          `${placeholder} alanına geçersiz email adresi girdiniz. ${value} `
        );
        isValid = false;
      }
    }

    if (rules.isNumeric) {
      const pattern = /^\d+$/;
      if (!pattern.test(value) && isValid) {
        setValidationError(
          ` ${placeholder} alanına sadece rakam girebilirsiniz. ${value}`
        );
        isValid = false;
      }
    }

    if (rules.minLength) {
      if (value.length < rules.minLength && isValid) {
        setValidationError(
          `${placeholder} alanı en az ${rules.minLength} karakter olmalıdır. ${value.length} karakter girdiniz.`
        );
        isValid = false;
      }
    }
    if (rules.maxLength) {
      if (value.length > rules.maxLength && isValid) {
        setValidationError(
          `${placeholder} alanı en fazla ${rules.maxLength} karakter olabilir. ${value.length} karakter girdiniz.`
        );
        isValid = false;
      }
    }
    // gg/aa/yy
    if (rules.date) {
      const pattern = /(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.]\d\d/;
      if (!pattern.test(value) && isValid) {
        setValidationError(
          ` ${placeholder} format hatalı, GG/AA/YY olacak şekilde giriniz. ${value}`
        );
        isValid = false;
      }
    }

    return isValid;
  };

  const submitHandler = () => {
    let formData = {};

    for (const key in profileForm) {
      formData[key] = profileForm[key].value;
    }
    formData["birthDate"] = birdthDate;
    profileImage === null && delete formData["imageData"];

    const body = {
      ReqToken: localStorage.getItem("REACT_TOKEN_USER"),
      ReqData: formData,
    };
    axios
      .post(updateProfilUrl, body)
      .then(function (response) {
        const res = response.data;
        // sonucu handle et
        if (res.ResCode !== 200) {
          //alert(res.ResMessage);
          NotificationManager.info(res.ResMessage);
          console.log("hatalar", res);
        } else {
          // kayit basarili burada gerekli islemleri yapacagiz
          NotificationManager.info(res.ResMessage);
          authCtx.onLogin(res.ResData);
          profileModal(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  let form = (
    <React.Fragment>
      <form>
        <div className="form-row">
          {profileFormArray.map((item) => (
            <Input
              key={item.id}
              divClassName={item.config.divClassName}
              label={item.config.label}
              placeholderText={item.config.placeholderText}
              birdthDate={birdthDate}
              setBirdthDate={setBirdthDate}
              profileImage={profileImage}
              setProfileImage={setProfileImage}
              elementType={item.config.elementType}
              elementConfig={item.config.elementConfig}
              value={item.config.value}
              invalid={!item.config.valid}
              shouldValidate={item.config.validation}
              touched={item.config.touched}
              validationError={validationError}
              changed={(event) => inputChangedHandler(event, item.id)}
              disabled={item.config.disabled}
              setIsProfilFormValid={setIsProfilFormValid}
            />
          ))}
        </div>
      </form>
      <div className="col-12 mt-4">
        <button
          className="button btn-block m-0"
          onClick={() => submitHandler()}
          disabled={isProfilFormValid ? "" : "disabled"}
        >
          Güncelle
        </button>
      </div>
    </React.Fragment>
  );
  return (
    <React.Fragment>
      <div
        className="mfp-bg mfp-no-margins mfp-fade mfp-ready"
        onClick={() => profileModal(false)}
      ></div>
      <div
        className="myModal"
        style={{
          maxWidth: "800px",
          maxHeight: "90%",
          overflowY: "scroll",
          overflowX: "hidden",
        }}
      >
        <div className="card mx-auto" style={{ maxWidth: "800px" }}>
          <div className="card-header py-3 bg-transparent center">
            <h3 className="mb-0 font-weight-normal">Bilgilerimi Güncelle</h3>
          </div>
          <div className="card-body mx-auto p-5">{form}</div>
        </div>
        <button
          title="Close (Esc)"
          type="button"
          className="mfp-close2"
          style={{ color: "#9D9D9D" }}
          onClick={() => profileModal(false)}
        >
          ×
        </button>
      </div>
    </React.Fragment>
  );
};

export default ProfileModal;
