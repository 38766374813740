// leaderboard.js
import React, { useState, Component, useEffect } from "react";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";

const LeaderHeader = (props) => {
  return (
    <div className="row justify-content-center center">
      <div className="col-lg-7">
        <div className="col-auto">
          <h2 className="nott ls0 h2 font-weight-bold">
            {props.leaderPageContent
              ? ReactHtmlParser(
                  props.leaderPageContent
                    .find((x) => x.contentKey === "leader.title")
                    .managementContent.replace("<p>", "")
                    .replace("</p>", "")
                )
              : null}
          </h2>
          <p className="text-muted mb-1">
            {props.leaderPageContent
              ? ReactHtmlParser(
                  props.leaderPageContent
                    .find((x) => x.contentKey === "leader.subtitle")
                    .managementContent.replace("<p>", "")
                    .replace("</p>", "")
                )
              : null}
          </p>

          <div className="clear" />
        </div>
      </div>
    </div>
  );
};

export default LeaderHeader;
