// projectItem.js
import React, { useState, Component, useEffect, useContext } from "react";
import ReactHtmlParser from "react-html-parser";
import Notification from "../parts/notification";
import AuthContext from "../../../store/authcontext";
import axios from "axios";
import * as constValues from "../../../utilities/constants";
import { Link } from "react-router-dom";

const ProjectItem = (props) => {
  const ctx = useContext(AuthContext);
  const [loadNotification, setLoadNotification] = useState(false);

  useEffect(() => {
    const existingScript = document.getElementById("fscript");
    if (existingScript) {
      existingScript.remove();
      const tag = document.createElement("script");
      tag.src = tag;
      tag.id = "fscript";
      tag.src = "/assets/js/functions.js?v=1.1";
      tag.async = true;
      document.body.appendChild(tag);
    }
  }, []);

  /** Project Detail Url */
  let url = "/projeler/proje-detay/" + props.project.id;

  const updatedProject = () => {
    let updatedProjectList = [...props.projectList];
    updatedProjectList.map((updatedProj) => {
      if (updatedProj.$id === props.project.$id) {
        updatedProj.notificationCount = 0;
      }
    });

    props.setProjectList(updatedProjectList);
  };

  return (
    <div
      className="col-lg-4 col-sm-6 mb-4"
      key={props.i}
      onMouseOver={() => {
        const desc1 = document.getElementsByClassName("desc1");
        const desc2 = document.getElementsByClassName("desc2");
        if (desc2.length !== 0) {
          desc1[props.i].style.display = "none";
          desc2[props.i].style.display = "block";
        }
      }}
      onMouseLeave={() => {
        const desc1 = document.getElementsByClassName("desc1");
        const desc2 = document.getElementsByClassName("desc2");
        if (desc2.length !== 0) {
          desc1[props.i].style.display = "block";
          desc2[props.i].style.display = "none";
        }
      }}
    >
      <div className="i-products">
        <div className="products-image">
          <Link to={url}>
            <img
              src={
                props.project && props.project.medias
                  ? props.project.medias[0].url
                  : null
              }
              alt={props.project ? props.project.name : null}
            />
            {/**<span className="badge">Gadgets</span>*/}
          </Link>
        </div>
        <div className="products-desc projectHeight">
          <h3 className="font-weight-bold">
            <Link to={url}>{props.project ? props.project.name : null}</Link>
          </h3>
          <p className="desc1">
            {props.project &&
            props.project.details &&
            props.project.details.length > 0
              ? ReactHtmlParser(
                  props.project.details[0].description
                    .replace("<p>", "")
                    .replace("</p>", "")
                    .substring(0, 100) + "..."
                )
              : ""}
          </p>
          <p className="desc2" style={{ display: "none" }}>
            {props.project &&
            props.project.details &&
            props.project.details.length > 1
              ? ReactHtmlParser(
                  props.project.details[1].description
                    .replace("<p>", "")
                    .replace("</p>", "")
                    .substring(0, 100) + "..."
                )
              : ""}
          </p>
          <div className="clear" />
          <ul className="skills projectSkills">
            <li data-percent={73}>
              <span className="d-flex justify-content-between w-100">
                <span className="counter">
                  {props.project
                    ? props.project.remainingTime
                    : "Bilgi bulunamadı"}
                </span>
                {ctx.isLoggedIn && (
                  <span className="">
                    <a
                      onClick={() => {
                        setLoadNotification(true);
                        updatedProject();
                      }}
                      id="top-cart-trigger"
                      data-toggle="modal"
                      data-target="#myModal"
                    >
                      <i
                        className="icon-line-bell"
                        style={{ fontSize: "20px" }}
                      ></i>
                      {props.project.notificationCount !== 0 && (
                        <span className="top-cart-number">
                          {props.project.notificationCount}
                        </span>
                      )}
                    </a>
                  </span>
                )}
              </span>
              <div className="progress" />
            </li>
          </ul>
          <div className="products-hoverlays">
            {/**  <span className="products-location"><i className="icon-map-marker1" /> Melbourne, Australia</span> */}
            <ul className="list-group-flush my-3 mb-0">
              {ctx.isLoggedIn ? (
                <li className="list-group-item">
                  {props.project && props.project.ideaCount
                    ? props.project.ideaCount + " "
                    : 0 + " "}
                  Fikir Sayısı
                </li>
              ) : null}
              <li className="list-group-item">
                {props.project && props.project.companyName
                  ? props.project.companyName
                  : null}
              </li>
              <li className="list-group-item">
                <i className="icon-map-marker1 mr-1"></i>
                {props.project && props.project.location
                  ? props.project.location
                  : null}
              </li>
            </ul>

            {/**
                         <div className="product-user d-flex align-items-center mt-4">
                         <img src="/assets/img/investors/1.jpg" alt="" />
                         <a href="demo-crowdfunding-single.html">By Jackson Pot </a>
                         </div>
                         */}
          </div>
          {loadNotification && (
            <Notification
              project={props.project}
              loadNotification={loadNotification}
              setLoadNotification={setLoadNotification}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default ProjectItem;
