// leaderboard.js
import React, { useContext } from "react";
import ReactHtmlParser from "react-html-parser";
import AuthContext from "../../../store/authcontext";

const LeaderTableItem = (props) => {
  const ctx = useContext(AuthContext);
  const getColor = (badge) => {
    switch (badge) {
      case 1:
        return "/assets/img/badges/medal_1.png";
      case 2:
        return "/assets/img/badges/medal_2.png";
      case 3:
        return "/assets/img/badges/medal_3.png";
      case 4:
        return "#/assets/img/badges/medal_4.png";
      case 5:
        return "/assets/img/badges/medal_5.png";
      case 6:
        return "/assets/img/badges/medal_6.png";
      default:
        break;
    }
  };

  const scroll = (tab) => {
    const element = document.getElementById(tab).getBoundingClientRect();
    if (element.top === 434 || element.top === 461) return;
    window.scrollTo({
      top: element.top,
      left: element.left,
      behavior: "smooth",
    });
  };

  return (
    <div>
      <div
        className="tabs mb-0 clearfix ui-tabs ui-corner-all ui-widget ui-widget-content"
        id="sidebar-tabs"
      >
        <div id="page-menu" data-mobile-sticky="true">
          <div id="page-menu-wrap" style={{ backgroundColor: "#F5F5F5" }}>
            <div className="container">
              <div
                className="page-menu-row"
                style={{ justifyContent: "center" }}
              >
                <ul
                  className="tab-nav clearfix ui-tabs-nav ui-corner-all ui-helper-reset ui-helper-clearfix ui-widget-header"
                  role="tablist"
                >
                  <li
                    role="tab"
                    tabindex="0"
                    className="ui-tabs-tab ui-corner-top ui-state-default ui-tab ui-tabs-active ui-state-active"
                    aria-controls="tabs-1"
                    aria-labelledby="ui-id-1"
                    aria-selected="true"
                    aria-expanded="true"
                  >
                    <a
                      href="#tabs-1"
                      role="presentation"
                      tabindex="-1"
                      className="ui-tabs-anchor"
                      id="ui-id-1"
                      onClick={() => scroll("tabContent1")}
                    >
                      {props.leaderPageContent
                        ? ReactHtmlParser(
                            props.leaderPageContent
                              .find(
                                (x) => x.contentKey === "leader.nav.left.title"
                              )
                              .managementContent.replace("<p>", "")
                              .replace("</p>", "")
                          )
                        : null}
                    </a>
                  </li>
                  <li
                    role="tab"
                    tabindex="-1"
                    className="ui-tabs-tab ui-corner-top ui-state-default ui-tab"
                    aria-controls="tabs-2"
                    aria-labelledby="ui-id-2"
                    aria-selected="false"
                    aria-expanded="false"
                  >
                    <a
                      href="#tabs-2"
                      role="presentation"
                      tabindex="-1"
                      className="ui-tabs-anchor"
                      id="ui-id-2"
                      onClick={() => scroll("tabContent2")}
                    >
                      {props.leaderPageContent
                        ? ReactHtmlParser(
                            props.leaderPageContent
                              .find(
                                (x) => x.contentKey === "leader.nav.right.title"
                              )
                              .managementContent.replace("<p>", "")
                              .replace("</p>", "")
                          )
                        : null}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="tab-container">
          <div
            className="tab-content clearfix ui-tabs-panel ui-corner-bottom ui-widget-content"
            id="tabs-1"
            aria-labelledby="ui-id-1"
            role="tabpanel"
            aria-hidden="false"
          >
            <ul className="list-group col-12 mb-4 dspFlex" id="tabContent1">
              {props.winnerListGeneral
                ? props.winnerListGeneral.map((leader, i) => {
                    let colorCode = getColor(leader.badge);
                    let liClass = "list-group-item dspFlex";
                    let liOwnClass = "leader-own-idea";
                    if (leader.isOwn) {
                      liClass = liClass + " " + liOwnClass;
                    }
                    return (
                      <li
                        key={i}
                        id={i}
                        className={liClass}
                        style={{ borderTopWidth: "1px" }}
                      >
                        <b className="leader-number">{leader.orderNo}.</b>{" "}
                        <img
                          src={
                            leader.imageUrl
                              ? leader.imageUrl
                              : "/assets/img/dummy-user.jpg"
                          }
                          className="user-image mr-3"
                        ></img>
                        <span className="leader-person-name">
                          {leader.userName}
                        </span>
                        <span className="leader-point-area">
                          {leader.point}{" "}
                          <img src={getColor(leader.badge)}></img>
                        </span>
                      </li>
                    );
                  })
                : null}
            </ul>
          </div>
          <div
            className="tab-content clearfix ui-tabs-panel ui-corner-bottom ui-widget-content"
            id="tabs-2"
            aria-labelledby="ui-id-2"
            role="tabpanel"
            aria-hidden="true"
            style={{ display: "none" }}
          >
            <ul className="list-group col-12 mb-4 dspFlex" id="tabContent2">
              {props.winnerListRelated ? (
                props.winnerListRelated.map((leader, i) => {
                  let colorCode = getColor(leader.badge);
                  let liClass = "list-group-item dspFlex";
                  let liOwnClass = "leader-own-idea";
                  if (leader.isOwn) {
                    liClass = liClass + " " + liOwnClass;
                  }
                  return (
                    <li
                      id={i}
                      className={liClass}
                      style={{ borderTopWidth: "1px" }}
                    >
                      <b className="leader-number">{leader.orderNo}.</b>{" "}
                      <img
                        src={
                          leader.imageUrl
                            ? leader.imageUrl
                            : "/assets/img/dummy-user.jpg"
                        }
                        className="user-image mr-3"
                      ></img>
                      <span className="leader-person-name">
                        {leader.userName}
                      </span>
                      <span className="leader-point-area">
                        {leader.point} <img src={getColor(leader.badge)}></img>
                      </span>
                    </li>
                  );
                })
              ) : (
                <p className="text-center">
                  {ctx.isLoggedIn
                    ? "Henüz eklediğin arkadaşın yok."
                    : "Arkadaşarını görebilmek için giriş yapmalısın."}
                </p>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeaderTableItem;
