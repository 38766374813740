// blogContent.js
import React, { useState, useEffect } from "react";
import Loader from "react-loader-spinner";
/**Parts */
import BlogItem from "../parts/blogItem";
import Pagination from "../../layouts/Pagination";

const BlogContent = (props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [blogPerPage, setBlogPerPage] = useState(6);

  // get current blogs
  const indexOfLastBlog = currentPage * blogPerPage;
  const indexOfFirstBlog = indexOfLastBlog - blogPerPage;
  const currentBlogs = props.blogList
    ? props.blogList.slice(indexOfFirstBlog, indexOfLastBlog)
    : null;

  // change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    const existingScript = document.getElementById("fscript");
    if (existingScript) {
      existingScript.remove();
      const tag = document.createElement("script");
      tag.src = tag;
      tag.id = "fscript";
      tag.src = "/assets/js/functions.js?v=1.1";
      tag.async = true;
      document.body.appendChild(tag);
    }
  });

  return (
    <div className="blog-container container">
      {props.blogList ? (
        <div id="blogs" className="row blogs">
          {currentBlogs.map((blog, i) => {
            return <BlogItem blog={blog} />;
          })}
        </div>
      ) : (
        <Loader
          style={{ textAlign: "center" }}
          type="ThreeDots"
          color="#31F4E8"
          height={75}
          width={75}
          timeout={10000}
        />
      )}
      <Pagination
        postsPerPage={blogPerPage}
        totalPosts={props.blogList ? props.blogList.length : 0}
        paginate={paginate}
        pageNumber={currentPage}
      />
    </div>
  );
};
export default BlogContent;
