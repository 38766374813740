// InvitieOptions.js
import React from "react";

/** Parts */
import RelatedInvites from "../parts/relatedInvites";
import PhoneInvites from "../parts/phoneInvites";

const InviteOptions = (props) => {
  return (
    <div className="col-md-3">
      <div className="list-group">
        <h3>
          <i className="icon-envelope2"></i> Davet Seçenekleri{" "}
        </h3>
        {/** <a onClick={() => { handleFBLogin(); }} className="list-group-item list-group-item-action d-flex justify-content-between"><div>Facebook ile Davet Edin</div><i className="icon-line-facebook" /></a>*/}
        <a
          href="#"
          className="list-group-item list-group-item-action d-flex justify-content-between"
          data-toggle="modal"
          data-target="#relatedInvitesModal"
        >
          <div>Favoriler </div>
          <i className="icon-line-star" />
        </a>
        <a
          href="#"
          className="list-group-item list-group-item-action d-flex justify-content-between"
          data-toggle="modal"
          data-target="#phoneInvitesModal"
        >
          <div>Telefon Numarası </div>
          <i className="icon-line-phone" />
        </a>
      </div>
      <RelatedInvites projectId={props.projectId} />
      <PhoneInvites
        projectId={props.projectId}
        setInvitationStatus={props.setInvitationStatus}
      />
    </div>
  );
};

export default InviteOptions;
