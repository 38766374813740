import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { useHistory } from "react-router";

import { API_URL, GET_USER_ALL_IDEAS_URL } from "../../utilities/constants";
import token from "../../utilities/token";

import ProfileModal from "./profileModals/ProfileModal";
import AuthContext from "../../store/authcontext";
import Settings from "../profile/profileModals/settings";
import Loader from "react-loader-spinner";

import { Helmet } from "react-helmet";

const Profile = () => {
  const [profileInfoModal, setProfileInfoModal] = useState(false);
  let history = useHistory();
  const getUserAllIdeasUrl = `${API_URL}${GET_USER_ALL_IDEAS_URL}`;
  const [userIdeas, setUserIdeas] = useState(null);
  const authCtx = useContext(AuthContext);
  const [userInfo, setUserInfo] = useState(authCtx.userInfo);
  const [isLoading, setIsLoading] = useState(true);

  !localStorage.getItem("isLoggedIn") && history.push("/");

  useEffect(() => {
    setUserInfo(authCtx.userInfo);
  }, [authCtx.userInfo]);

  useEffect(() => {
    const getUserAllIdeas = () => {
      setIsLoading(true);
      const body = {
        ReqToken: localStorage.getItem("REACT_TOKEN_USER"),
        ReqData: authCtx.isLoggedIn ? authCtx.userInfo._id : null,
      };
      axios
        .post(getUserAllIdeasUrl, body)
        .then(function (response) {
          const res = response.data;
          // sonucu handle et
          if (res.ResCode !== 200) {
            //alert(res.ResMessage);
            NotificationManager.info(res.ResMessage);
          } else {
            // kayit basarili burada gerekli islemleri yapacagiz
            setUserIdeas(res.ResData);
            setIsLoading(false);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    getUserAllIdeas();
  }, []);

  useEffect(() => {
    const existingScript = document.getElementById("fscript");
    if (existingScript) {
      existingScript.remove();
      const tag = document.createElement("script");
      tag.src = tag;
      tag.id = "fscript";
      tag.src = "/assets/js/functions.js?v=1.1";
      tag.async = true;
      document.body.appendChild(tag);
    }
  }, []);

  const logout = () => {
    authCtx.onLogout();
    localStorage.setItem("COMES_FROM_IDEA", null);
    token().getToken();
    history.push("/");
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  const getBadge = (badge) => {
    switch (badge) {
      case 1:
        return "/assets/img/badges/medal_1.png";
        break;
      case 2:
        return "/assets/img/badges/medal_2.png";
        break;
      case 3:
        return "/assets/img/badges/medal_3.png";
        break;
      case 4:
        return "#/assets/img/badges/medal_4.png";
        break;
      case 5:
        return "/assets/img/badges/medal_5.png";
        break;
      case 6:
        return "/assets/img/badges/medal_6.png";
        break;
      default:
        break;
    }
  };

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Fikrimiz - Profilim</title>
        <meta name="description" content="Sosyal Yaratıcılık Başlıyor" />
        <meta
          name="keywords"
          content="Fikrimiz, Yaratıcılık, Yarışma, Sosyal, Reklam, Blog"
        />
        <meta property="og:image" content="/assets/img/fikrimiz-org-3.png" />
      </Helmet>
      {profileInfoModal && <ProfileModal profileModal={setProfileInfoModal} />}
      <section id="content">
        <div className="content-wrap">
          <div className="container clearfix">
            <div className="row clearfix">
              <div className="col-md-9">
                <img
                  src={
                    userInfo !== null && userInfo.imageUrl
                      ? userInfo.imageUrl
                      : "/assets/img/dummy-user.jpg"
                  }
                  className="alignleft img-circle img-thumbnail my-0"
                  alt="Avatar"
                  style={{ maxWidth: "84px" }}
                ></img>

                <div className="heading-block border-0">
                  <div style={{ display: "flex" }}>
                    <h3 style={{ display: "flex", flex: "1" }}>
                      {userInfo !== null && userInfo.name}{" "}
                      {userInfo !== null && userInfo.lastName}
                    </h3>
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: "25px",
                        }}
                      >
                        {userInfo !== null && userInfo.point}
                      </span>
                      <img
                        style={{ width: "60px" }}
                        src={getBadge(userInfo !== null && userInfo.badge)}
                      ></img>
                    </div>
                  </div>
                  <span>Profil Bilgileriniz</span>
                </div>
                <div className="clear"></div>

                <div className="row clearfix">
                  <div className="col-lg-12">
                    <div className="tabs tabs-alt clearfix" id="tabs-profile">
                      <ul className="tab-nav clearfix">
                        <li>
                          <a href="#tab-feeds">
                            <i className="icon-pencil2"></i> Fikirlerim
                          </a>
                        </li>
                        {/**
                         *                         <li>
                          <a href="#tab-posts">
                            <i className="icon-pencil2"></i> Posts
                          </a>
                        </li>
                        <li>
                          <a href="#tab-replies">
                            <i className="icon-reply"></i> Replies
                          </a>
                        </li>
                        <li>
                          <a href="#tab-connections">
                            <i className="icon-users"></i> Connections
                          </a>
                        </li>
                         */}
                      </ul>

                      <div className="tab-container">
                        <div className="tab-content clearfix" id="tab-feeds">
                          <p>
                            Aşağıda bulunan tabloda şimdiye kadar vermiş
                            olduğunuz fikirler ve ona bağlı olan projeler yer
                            almaktadır
                          </p>

                          <table className="table table-bordered table-striped">
                            <thead>
                              <tr>
                                <th>Başlık</th>
                                <th>İçerik</th>
                                <th>Proje</th>
                              </tr>
                            </thead>
                            <tbody>
                              {isLoading ? (
                                <tr>
                                  <td colSpan="3">
                                    <Loader
                                      style={{ textAlign: "center" }}
                                      type="ThreeDots"
                                      color="#31F4E8"
                                      height={50}
                                      width={50}
                                    />
                                  </td>
                                </tr>
                              ) : userIdeas && userIdeas.length !== 0 ? (
                                userIdeas.map((item, index) => (
                                  <tr key={index}>
                                    <td>
                                      <code>{item.caption}</code>
                                    </td>
                                    <td>{item.idea}</td>
                                    <td>
                                      <a
                                        href={`/projeler/proje-detay/${item.projectId}`}
                                      >
                                        Projeye Git
                                      </a>
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan="3">Henüz hiç fikriniz yok</td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-100 line d-block d-md-none"></div>

              <div className="col-md-3">
                <div className="list-group">
                  <a
                    onClick={() => setProfileInfoModal(true)}
                    className="list-group-item list-group-item-action d-flex justify-content-between"
                  >
                    <div>Bilgilerimi Güncelle</div>
                    <i className="icon-user"></i>
                  </a>

                  <a
                    data-toggle="modal"
                    data-target="#settingsModal"
                    className="list-group-item list-group-item-action d-flex justify-content-between"
                  >
                    <div>Ayarlarım</div>
                    <i className="icon-cog"></i>
                  </a>
                  <Settings />
                  <a
                    onClick={() => logout()}
                    className="list-group-item list-group-item-action d-flex justify-content-between"
                  >
                    <div>Çıkış Yap</div>
                    <i className="icon-line2-logout"></i>
                  </a>
                </div>
                {/** 
                <div className="fancy-title topmargin title-border">
                  <h4>About Me</h4>
                </div>

                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Ipsum laboriosam, dignissimos veniam obcaecati. Quasi eaque,
                  odio assumenda porro explicabo laborum!
                </p>

                <div className="fancy-title topmargin title-border">
                  <h4>Social Profiles</h4>
                </div>

                <a
                  href="#"
                  className="social-icon si-facebook si-small si-rounded si-light"
                  title="Facebook"
                >
                  <i className="icon-facebook"></i>
                  <i className="icon-facebook"></i>
                </a>

                <a
                  href="#"
                  className="social-icon si-gplus si-small si-rounded si-light"
                  title="Google+"
                >
                  <i className="icon-gplus"></i>
                  <i className="icon-gplus"></i>
                </a>

                <a
                  href="#"
                  className="social-icon si-dribbble si-small si-rounded si-light"
                  title="Dribbble"
                >
                  <i className="icon-dribbble"></i>
                  <i className="icon-dribbble"></i>
                </a>

                <a
                  href="#"
                  className="social-icon si-flickr si-small si-rounded si-light"
                  title="Flickr"
                >
                  <i className="icon-flickr"></i>
                  <i className="icon-flickr"></i>
                </a>

                <a
                  href="#"
                  className="social-icon si-linkedin si-small si-rounded si-light"
                  title="LinkedIn"
                >
                  <i className="icon-linkedin"></i>
                  <i className="icon-linkedin"></i>
                </a>

                <a
                  href="#"
                  className="social-icon si-twitter si-small si-rounded si-light"
                  title="Twitter"
                >
                  <i className="icon-twitter"></i>
                  <i className="icon-twitter"></i>
                </a>
                */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Profile;
